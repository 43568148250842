import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { Accordion, Form, Button } from '@themesberg/react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select';

export default (props) => {

  /***
   * SELECT DINAMICOS
   */

  /******** MODELOS DE CABEZA *********/
  const [items, setItems] = useState([])
  function hierros() {
    axios.get(`${process.env.REACT_APP_BASE_URL}/modelo-cabeza-get`)
      .then((response) => {
        setItems(response.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  /******** MODELOS DE CABEZA DRIVER *********/
  const [driver, setDriver] = useState([])
  function drivers() {
    axios.get(`${process.env.REACT_APP_BASE_URL}/modelo-varillas-get`)
      .then((response) => {
        setDriver(response.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  /******** WEDGES *********/
  const [wedges, setWedges] = useState([])
  function wedgesAll() {
    axios.get(`${process.env.REACT_APP_BASE_URL}/wedges-get`)
      .then((response) => {
        setWedges(response.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  /******** IRON DRIVING *********/
  const [IronDriving, setIronDriving] = useState([])
  function IronDrivingAll() {
    axios.get(`${process.env.REACT_APP_BASE_URL}/mdc-iron-driving-get`)
      .then((response) => {
        setIronDriving(response.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    hierros()
    drivers()
    wedgesAll()
    IronDrivingAll()
  }, [])

  /***
   * SELECT DINAMICOS
   */
  const id = props.id
  const e = ""
  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: 'onBlur' })

  const [idRecTeorica, setIdRecTeorica] = useState(0)
  const [idHierros, setIdHierros] = useState("")
  const [idHierrosII, setIdHierrosII] = useState("")
  const [idDriver, setIdDriver] = useState("")
  const [idMadera, setIdMadera] = useState("")
  const [idWedges, setIdWedges] = useState("")
  const [idIronDriving, setIdIronDriving] = useState("")

  const [numHIerros3, setNumHIerros3] = useState(0)
  const [numHIerros4, setNumHIerros4] = useState(0)
  const [numHIerros5, setNumHIerros5] = useState(0)
  const [numHIerros6, setNumHIerros6] = useState(0)
  const [numHIerros7, setNumHIerros7] = useState(0)
  const [numHIerros8, setNumHIerros8] = useState(0)
  const [numHIerros9, setNumHIerros9] = useState(0)
  const [numHIerrosPw, setNumHIerrosPw] = useState(0)

  const [numHIerros3II, setNumHIerros3II] = useState(0)
  const [numHIerros4II, setNumHIerros4II] = useState(0)
  const [numHIerros5II, setNumHIerros5II] = useState(0)
  const [numHIerros6II, setNumHIerros6II] = useState(0)
  const [numHIerros7II, setNumHIerros7II] = useState(0)
  const [numHIerros8II, setNumHIerros8II] = useState(0)
  const [numHIerros9II, setNumHIerros9II] = useState(0)
  const [numHIerrosPwII, setNumHIerrosPwII] = useState(0)

  const [numMaderas3, setNumMaderas3] = useState(0)
  const [numMaderas5, setNumMaderas5] = useState(0)
  const [numMaderas7, setNumMaderas7] = useState(0)
  const [numMaderas9, setNumMaderas9] = useState(0)

  const [numHibrido2, setNumHibrido2] = useState(0)
  const [numHibrido3, setNumHibrido3] = useState(0)
  const [numHibrido4, setNumHibrido4] = useState(0)
  const [numHibrido5, setNumHibrido5] = useState(0)
  const [numHibrido6, setNumHibrido6] = useState(0)

  const [numWedges43, setNumWedges43] = useState(0)
  const [numWedges48, setNumWedges48] = useState(0)
  const [numWedges50, setNumWedges50] = useState(0)
  const [numWedges52, setNumWedges52] = useState(0)
  const [numWedges54, setNumWedges54] = useState(0)
  const [numWedges56, setNumWedges56] = useState(0)
  const [numWedges58, setNumWedges58] = useState(0)
  const [numWedges60, setNumWedges60] = useState(0)
  const [numWedges62, setNumWedges62] = useState(0)
  const [numWedges64, setNumWedges64] = useState(0)

  const [numIronDriving3, setNumIronDriving3] = useState(0)
  const [numIronDriving4, setNumIronDriving4] = useState(0)
  const [numIronDriving5, setNumIronDriving5] = useState(0)
  const [numIronDriving6, setNumIronDriving6] = useState(0)


  function getRecomendacionTeorica() {
    let defaultValues = {}
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/recomendacion-teorica-get`, {
        params: { id },
      })
      .then((response) => {
        if (response != 'vacio') {
          setIdRecTeorica(response.data[0].id)
          setIdHierros(response.data[0].hierros)
          setIdHierrosII(response.data[0].hierros_II)
          setIdDriver(response.data[0].driver)
          setIdMadera(response.data[0].madera)
          setIdWedges(response.data[0].wedges)
          setIdIronDriving(response.data[0].iron)
          setNumHIerros3(response.data[0].numero_hierros == 1 && true)
          setNumHIerros4(response.data[0].numero_hierros4 == 1 && true)
          setNumHIerros5(response.data[0].numero_hierros5 == 1 && true)
          setNumHIerros6(response.data[0].numero_hierros6 == 1 && true)
          setNumHIerros7(response.data[0].numero_hierros7 == 1 && true)
          setNumHIerros8(response.data[0].numero_hierros8 == 1 && true)
          setNumHIerros9(response.data[0].numero_hierros9 == 1 && true)
          setNumHIerrosPw(response.data[0].numero_hierrosPW == 1 && true)

          setNumHIerros3II(response.data[0].numero_hierros_II == 1 && true)
          setNumHIerros4II(response.data[0].numero_hierros4_II == 1 && true)
          setNumHIerros5II(response.data[0].numero_hierros5_II == 1 && true)
          setNumHIerros6II(response.data[0].numero_hierros6_II == 1 && true)
          setNumHIerros7II(response.data[0].numero_hierros7_II == 1 && true)
          setNumHIerros8II(response.data[0].numero_hierros8_II == 1 && true)
          setNumHIerros9II(response.data[0].numero_hierros9_II == 1 && true)
          setNumHIerrosPwII(response.data[0].numero_hierrosPW_II == 1 && true)

          setNumMaderas3(response.data[0].numero_maderas == 1 && true)
          setNumMaderas5(response.data[0].numero_maderas2 == 1 && true)
          setNumMaderas7(response.data[0].numero_maderas3 == 1 && true)
          setNumMaderas9(response.data[0].numero_maderas4 == 1 && true)

          setNumHibrido2(response.data[0].numero_hibridos == 1 && true)
          setNumHibrido3(response.data[0].numero_hibridos2 == 1 && true)
          setNumHibrido4(response.data[0].numero_hibridos3 == 1 && true)
          setNumHibrido5(response.data[0].numero_hibridos4 == 1 && true)
          setNumHibrido6(response.data[0].numero_hibridos5 == 1 && true)

          setNumWedges43(response.data[0].numero_wedges == 1 && true)
          setNumWedges48(response.data[0].numero_wedges2 == 1 && true)
          setNumWedges50(response.data[0].numero_wedges3 == 1 && true)
          setNumWedges52(response.data[0].numero_wedges4 == 1 && true)
          setNumWedges54(response.data[0].numero_wedges5 == 1 && true)
          setNumWedges56(response.data[0].numero_wedges6 == 1 && true)
          setNumWedges58(response.data[0].numero_wedges7 == 1 && true)
          setNumWedges60(response.data[0].numero_wedges8 == 1 && true)
          setNumWedges62(response.data[0].numero_wedges9 == 1 && true)
          setNumWedges64(response.data[0].numero_wedges10 == 1 && true)

          setNumIronDriving3(response.data[0].numero_driving == 1 && true)
          setNumIronDriving4(response.data[0].numero_driving2 == 1 && true)
          setNumIronDriving5(response.data[0].numero_driving3 == 1 && true)
          setNumIronDriving6(response.data[0].numero_driving4 == 1 && true)

          defaultValues.id_rec_teorica = response.data[0].id
          defaultValues.numero_hierros = response.data[0].numero_hierros
          defaultValues.id_informe_tecnico = response.data[0].id_informe_tecnico
          defaultValues.id_modelo_driving = response.data[0].id_modelo_driving
          defaultValues.id_modelo_wedges = response.data[0].id_modelo_wedges
          defaultValues.id_modelo_driver = response.data[0].id_modelo_driver
          defaultValues.id_modelo_maderas = response.data[0].id_modelo_maderas
          defaultValues.id_modelo_hierros = response.data[0].id_modelo_hierros
          defaultValues.id_modelo_hierros_II = response.data[0].id_modelo_hierros_II
          defaultValues.id_modelo_maderas = response.data[0].id_modelo_maderas
          defaultValues.loftDriver = response.data[0].loftDriver
          reset({ ...defaultValues })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getRecomendacionTeorica()
  }, [])

  function insRecomendacion(data) {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/recomendacion-teorica-ins`, {
        data: data,
        numHIerros3: numHIerros3,
        numHIerros4: numHIerros4,
        numHIerros5: numHIerros5,
        numHIerros6: numHIerros6,
        numHIerros7: numHIerros7,
        numHIerros8: numHIerros8,
        numHIerros9: numHIerros9,
        numHIerrosPw: numHIerrosPw,
        numHIerros3II: numHIerros3II,
        numHIerros4II: numHIerros4II,
        numHIerros5II: numHIerros5II,
        numHIerros6II: numHIerros6II,
        numHIerros7II: numHIerros7II,
        numHIerros8II: numHIerros8II,
        numHIerros9II: numHIerros9II,
        numHIerrosPwII: numHIerrosPwII,
        numMaderas3: numMaderas3,
        numMaderas5: numMaderas5,
        numMaderas7: numMaderas7,
        numMaderas9: numMaderas9,
        numHibrido2: numHibrido2,
        numHibrido3: numHibrido3,
        numHibrido4: numHibrido4,
        numHibrido5: numHibrido5,
        numHibrido6: numHibrido6,
        numWedges43: numWedges43,
        numWedges48: numWedges48,
        numWedges50: numWedges50,
        numWedges52: numWedges52,
        numWedges54: numWedges54,
        numWedges56: numWedges56,
        numWedges58: numWedges58,
        numWedges60: numWedges60,
        numWedges62: numWedges62,
        numWedges64: numWedges64,
        numIronDriving3: numIronDriving3,
        numIronDriving4: numIronDriving4,
        numIronDriving5: numIronDriving5,
        numIronDriving6: numIronDriving6
      })
      .then((res) => {
        Swal.fire({
          html: '<i>Process complete!</i>',
          icon: 'success',
          timer: 2000,
        }).then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const onSubmit = (data) => {
    if (idRecTeorica > 0) {
      axios
        .delete(`${process.env.REACT_APP_BASE_URL}/recomendacion-teorica-del/${id}`)
        .then((res) => {
          insRecomendacion(data)
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      insRecomendacion(data)
    }
  }

  return (
    <Accordion.Item eventKey="7" className="accordion-item-info mt-4">
      <Accordion.Button
        variant="link"
        className="w-100 d-flex justify-content-between accordion-button-info"
      >
        <span className="h6 mb-0 fw-bold">Recomendación teórica del equipo</span>
      </Accordion.Button>
      <Accordion.Body className="table-bording">
        <Form onSubmit={handleSubmit(onSubmit)}>

          <input type="hidden" value={id} {...register('id_informe_tecnico')} />
          <input type="hidden" {...register('id_rec_teorica')} />
          <div className="row">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" colSpan={'2'}>
                    Item
                  </th>
                  <th scope="col" colSpan={'5'}>
                    Modelo de cabezas
                  </th>
                  <th scope="col" colSpan={'5'}>
                    Número
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={'2'}>Hierros</td>
                  <td colSpan={'5'}>
                    <Form.Group>
                      <b>{idHierros}</b>
                      <Controller
                        control={control}
                        name="id_modelo_hierros"
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            defaultValue={items.filter(element => ({ label: element.name, value: element.id === 8 }))}
                            options={items.map(element => ({ label: element.name, value: element.id }))}
                            onChange={(val) => onChange(val.value)}
                          />
                        )}
                      />
                    </Form.Group>
                  </td>
                  <td colSpan={'5'}>
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      3
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name='numero_hierros'
                      checked={numHIerros3}
                      onChange={(event) => { setNumHIerros3(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      4
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name='numero_hierros4'
                      checked={numHIerros4}
                      onChange={(event) => { setNumHIerros4(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      5
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name='numero_hierros5'
                      checked={numHIerros5}
                      onChange={(event) => { setNumHIerros5(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      6
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numHIerros6}
                      onChange={(event) => { setNumHIerros6(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >

                      7
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numHIerros7}
                      onChange={(event) => { setNumHIerros7(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >

                      8
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numHIerros8}
                      onChange={(event) => { setNumHIerros8(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      9
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numHIerros9}
                      onChange={(event) => { setNumHIerros9(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      PW
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numHIerrosPw}
                      onChange={(event) => { setNumHIerrosPw(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                  </td>
                </tr>
                <tr>
                  <td colSpan={'2'}>Hierros II</td>
                  <td colSpan={'5'}>
                    <Form.Group>
                      <b>{idHierrosII}</b>
                      <Controller
                        control={control}
                        name="id_modelo_hierros_II"
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            defaultValue={items.filter(element => ({ label: element.name, value: element.id === 8 }))}
                            options={items.map(element => ({ label: element.name, value: element.id }))}
                            onChange={(val) => onChange(val.value)}
                          />
                        )}
                      />
                    </Form.Group>
                  </td>
                  <td colSpan={'5'}>
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      3
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name='numero_hierros'
                      checked={numHIerros3II}
                      onChange={(event) => { setNumHIerros3II(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      4
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name='numero_hierros4'
                      checked={numHIerros4II}
                      onChange={(event) => { setNumHIerros4II(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      5
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name='numero_hierros5'
                      checked={numHIerros5II}
                      onChange={(event) => { setNumHIerros5II(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      6
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numHIerros6II}
                      onChange={(event) => { setNumHIerros6II(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      7
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numHIerros7II}
                      onChange={(event) => { setNumHIerros7II(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      8
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numHIerros8II}
                      onChange={(event) => { setNumHIerros8II(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      9
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numHIerros9II}
                      onChange={(event) => { setNumHIerros9II(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      PW
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numHIerrosPwII}
                      onChange={(event) => { setNumHIerrosPwII(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
            <div className='row'>
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ width: '2%' }}>Palos largos</td>
                  <td style={{ width: '9%' }}>
                    <Form.Group>
                      <b>{idMadera}</b>
                      <Controller
                        control={control}
                        name="id_modelo_maderas"
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            options={driver.map(element => ({ label: element.name, value: element.id }))}
                            onChange={(val) => onChange(val.value)}
                          />
                        )}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label mb-3"
                      htmlFor="flexCheckDefault"
                    >
                      W3
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numMaderas3}
                      onChange={(event) => { setNumMaderas3(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label mb-3"
                      htmlFor="flexCheckDefault"
                    >
                      W5
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numMaderas5}
                      onChange={(event) => { setNumMaderas5(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label mb-3"
                      htmlFor="flexCheckDefault"
                    >
                      W7
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numMaderas7}
                      onChange={(event) => { setNumMaderas7(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label mb-3"
                      htmlFor="flexCheckDefault"
                    >
                      W9
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numMaderas9}
                      onChange={(event) => { setNumMaderas9(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                  </td>
                </tr>
                <tr>
                  <td>Palos largos</td>
                  <td>
                    <Form.Group>
                      <b>{idDriver}</b>
                      <Controller
                        control={control}
                        name="id_modelo_driver"
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            options={driver.map(element => ({ label: element.name, value: element.id }))}
                            onChange={(val) => onChange(val.value)}
                          />
                        )}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label mb-3"
                      htmlFor="flexCheckDefault"
                    >
                      HYB 2
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numHibrido2}
                      onChange={(event) => { setNumHibrido2(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label mb-3"
                      htmlFor="flexCheckDefault"
                    >
                      HYB 3
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numHibrido3}
                      onChange={(event) => { setNumHibrido3(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label mb-3"
                      htmlFor="flexCheckDefault"
                    >
                      HYB 4
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numHibrido4}
                      onChange={(event) => { setNumHibrido4(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label mb-3"
                      htmlFor="flexCheckDefault"
                    >
                      HYB 5
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numHibrido5}
                      onChange={(event) => { setNumHibrido5(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label mb-3"
                      htmlFor="flexCheckDefault"
                    >
                      HYB 6
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numHibrido6}
                      onChange={(event) => { setNumHibrido6(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td style={{ width: '22%' }}>
                    <Form.Group>
                      <Form.Select
                        defaultValue="0"
                        {...register('loftDriver')}
                      >
                        <option value="">Loft Driver</option>
                        <option value="8.0°">DR 8.0°</option>
                        <option value="8.5°">DR 8.5°</option>
                        <option value="9.0°">DR 9.0°</option>
                        <option value="9.5°">DR 9.5°</option>
                        <option value="10°">DR 10°</option>
                        <option value="10.5°">DR 10.5°</option>
                        <option value="11°">DR 11°</option>
                        <option value="11.5°">DR 11.5°</option>
                        <option value="12°">DR 12°</option>
                        <option value="12.5°">DR 12.5°</option>
                        <option value="13°">DR 13°</option>
                        <option value="13.5°">DR 13.5°</option>
                        <option value="14°">DR 14°</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row">
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ width: '10%' }}>Wedges</td>
                  <td style={{ width: '22%' }}>
                    <Form.Group>
                      <b>{idWedges}</b>
                      <Controller
                        control={control}
                        name="id_modelo_wedges"
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            options={wedges.map(element => ({ label: element.name, value: element.id }))}
                            onChange={(val) => onChange(val.value)}
                          />
                        )}
                      />
                    </Form.Group>
                  </td>
                  <td colSpan={'3'}>
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      PW 43°
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numWedges43}
                      onChange={(event) => { setNumWedges43(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      PW 48°
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numWedges48}
                      onChange={(event) => { setNumWedges48(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      AW 50°
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numWedges50}
                      onChange={(event) => { setNumWedges50(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      AW 52°
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numWedges52}
                      onChange={(event) => { setNumWedges52(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      SW 54°
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numWedges54}
                      onChange={(event) => { setNumWedges54(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      SW 56°
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numWedges56}
                      onChange={(event) => { setNumWedges56(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      LW 58°
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numWedges58}
                      onChange={(event) => { setNumWedges58(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      LW 60°
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numWedges60}
                      onChange={(event) => { setNumWedges60(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      LW 62°
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numWedges62}
                      onChange={(event) => { setNumWedges62(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      LW 64°
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numWedges64}
                      onChange={(event) => { setNumWedges64(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row">
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ width: '10%' }}>Iron driving</td>
                  <td style={{ width: '22%' }}>
                    <Form.Group>
                      <b>{idIronDriving}</b>
                      <Controller
                        control={control}
                        name="id_modelo_driving"
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            options={IronDriving.map(element => ({ label: element.name, value: element.id }))}
                            onChange={(val) => onChange(val.value)}
                          />
                        )}
                      />
                    </Form.Group>
                  </td>
                  <td colSpan={'3'}>
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      ID 2
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numIronDriving3}
                      onChange={(event) => { setNumIronDriving3(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      ID 3
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numIronDriving4}
                      onChange={(event) => { setNumIronDriving4(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      ID 4
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numIronDriving5}
                      onChange={(event) => { setNumIronDriving5(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      ID 5
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={numIronDriving6}
                      onChange={(event) => { setNumIronDriving6(event.target.checked) }}
                    />
                    <b style={{ marginLeft: '8px' }}>|</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-3">
            {idRecTeorica ? (
              <Button variant="success" type="submit">
                Actualizar
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Guardar
              </Button>
            )}
          </div>
        </Form>
      </Accordion.Body >
    </Accordion.Item >
  )
}
