import React, { useState, useEffect } from 'react'
import axios from 'axios'
import StaticModal from './Functions/functions-modal-informe'
import StaticModalAct from './Functions/functions-actualizar-informe'
import Slider from './Functions/functions-modal-imagenes-slider'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import {
  Accordion
} from '@themesberg/react-bootstrap'

export default (props) => {

  const id = props.id
  const [idIdentActual, setIdIdentActual] = useState(0)
  const [idIdentActualAct, setIdIdentActualAct] = useState(0)
  const [tablaIdentActual, setTablaIdentActual] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  function getIdentActual() {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/identificacion-actual-all-get`, {
        params: { id },
      })
      .then((response) => {
        if (response != 'vacio') {
          setIdIdentActual(1)
          setTablaIdentActual(response.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getIdentActual()
  }, [])

  const handleCheckboxChange = (valId) => {
    setSelectedIds((prevIds) =>
      prevIds.includes(valId)
        ? prevIds.filter((id) => id !== valId)
        : [...prevIds, valId]
    )
  }

  const handleSaveSelected = () => {
    if (selectedIds.length === 0) {
      Swal.fire('No hay elementos seleccionados.', '', 'warning')
      return
    }
    setIsLoading(true)

    try {
      axios.post(`${process.env.REACT_APP_BASE_URL}/guardar-seleccionados`, {
        ids: selectedIds,
      }).then((response) => {
        Swal.fire('Información guardada correctamente!', '', 'success');
        setSelectedIds([]);
        setIsLoading(false)
        getIdentActual();
      })
    } catch (error) {
      console.log(error);
      Swal.fire('Hubo un error al guardar la información.', '', 'error');
    }
  }

  const deleteID = (id) => {
    Swal.fire({
      title: 'Estas seguro de eliminar este registro?',
      text: 'No podrás revertir esto.!',
      icon: 'Cuidado',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/equipo-actual-del/${id}`)
          .then((res) => {
            Swal.fire({
              title: 'Eliminado!',
              icon: 'success',
              time: 1000,
            })
            getIdentActual()
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
  }

  return (
    <Accordion.Item eventKey="2" className="mt-4">
      <Accordion.Button
        variant="link"
        className="w-100 d-flex justify-content-between accordion-button-info"
      >
        <span className="h6 mb-0 fw-bold">
          Identificación del equipo actual
        </span>
      </Accordion.Button>
      <Accordion.Body className='table-bording'>
        <StaticModal id={props.id} />
        {
          idIdentActual > 0
          &&
          <div className="row">
            <table className="table table_class_informe">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Tipo de palo</th>
                  <th scope="col">Marca Palos</th>
                  <th scope="col">Modelo Cabeza</th>
                  <th scope="col">Tipo Cabeza</th>
                  <th scope="col" className='text-center'>Loft Cabeza</th>
                  <th scope="col">Número Cabeza / desde - hasta</th>
                  <th scope="col">Modelo Varilla</th>
                  <th scope="col">Fabrica</th>
                  <th scope="col">Material</th>
                  <th scope="col" className='text-center'>Flex</th>
                  <th scope="col" className='text-center'>Peso</th>
                  <th scope="col">Fotos equipo actual</th>
                  <th scope="col">Precio</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {
                  Array.isArray(tablaIdentActual) && tablaIdentActual.length > 0 &&
                  tablaIdentActual.map((val, key) => {
                    return <tr key={key}>
                      <td className='textCenter'>
                        {val.publicado === 0 && (
                          <input
                            type="checkbox"
                            value="yes"
                            checked={selectedIds.includes(val.id)}
                            onChange={() => handleCheckboxChange(val.id)}
                          />
                        )}
                      </td>
                      <td>{val.namTipPalo}</td>
                      <td>{val.id_marcas}</td>
                      <td>{val.id_modelo_cabeza}</td>
                      <td>{val.namTipCabeza}</td>
                      <td className='text-center'>{val.loftCabeza}</td>
                      <td className='text-center'>{val.desde > 0 ? val.desde + " - " + val.hasta : val.numero_cabeza}</td>
                      <td>{val.id_palo}</td>
                      <td>{val.fabrica}</td>
                      <td>{val.namMaterial}</td>
                      <td className='text-center'>{val.namFlex}</td>
                      <td className='text-center'>{val.peso}</td>
                      <td className='textCenter'>
                        <Slider
                          id={val.id}
                        />
                      </td>
                      <td>{val.precio}</td>
                      <td>
                        <button type="button" className="btn btn-danger btn-sm mx-2"
                          onClick={() => { deleteID(val.id) }}>
                          Eliminar
                        </button>
                        <React.Fragment>
                          <Link to={`/fitting/fitting-details/functions-actualizar-informe/${val.id}`}>
                            <button type="button" className="btn btn-warning btn-sm">
                              Actualizar
                            </button>
                          </Link>
                        </React.Fragment>
                      </td>
                    </tr>
                  })
                }
              </tbody>
            </table>
            <div className="text-end mt-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSaveSelected}
                disabled={isLoading} 
              >
                 {isLoading ? 'Guardando...' : 'Guardar Seleccionados'}
              </button>
            </div>
          </div>
        }
      </Accordion.Body>
    </Accordion.Item>
  )
}
