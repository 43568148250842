import React, { useState, useEffect } from 'react'
import { Accordion, Form } from '@themesberg/react-bootstrap'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import Swal from 'sweetalert2'
import { ListName } from './Functions/functions-listas-select'

export default (props) => {
  const id = props.id
  const [idCabezaDriver, setIdCabezaDriver] = useState(0)
  const [idCabezaDriverAct, setIdCabezaDriverAct] = useState(0)
  const [tablaCabezaDriver, setTablaCabezaDriver] = useState([])
  const [idCabezaDriverActual, setIdCabezaDriverActual] = useState(0)
  const [idCabezaDriverActual2, setIdCabezaDriverActual2] = useState(0)
  const [modeloVarillas, setModeloVarillas] = useState('')
  const [marcaCabeza, setMarcaCabeza] = useState('')
  const [flex, setFlex] = useState('')
  const [modeloCabeza, setModeloCabeza] = useState('')
  const [tipoCabeza, setTipoCabeza] = useState('')
  const [peso, setPeso] = useState('')
  const [material, setMaterial] = useState('')
  //Id equipo actual
  const [idMaterial, setIdMaterial] = useState('')
  const [idMarca, setIdMarca] = useState('')
  const [idModeloCabeza, setIdModeloCabeza] = useState('')
  const [idModeloVarilla, setIdModeloVarilla] = useState('')
  const [idTipoCabezas, setIdTipoCabezas] = useState('')
  const [idFlex, setIdFlex] = useState('')
  const [loft, setLoft] = useState('')
  const [okActual, setOkActual] = useState('')
  const [okActual2, setOkActual2] = useState('')


  function getCabezaDriver() {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/cabezas-driver-all-get`, {
        params: { id },
      })
      .then((response) => {
        if (response != 'vacio') {
          setTablaCabezaDriver(response.data)
          setIdCabezaDriver(response.data[0].id_informe_tecnico)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getVarillasHierrosAct = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/driver-actual-get`, {
        params: { id },
      })
      .then((response) => {
        if (response != 'vacio') {
          setModeloVarillas(response.data[0].nomModVarilla)
          setMarcaCabeza(response.data[0].namMarca)
          setFlex(response.data[0].nameFlex)
          setModeloCabeza(response.data[0].nomModelo)
          setTipoCabeza(response.data[0].namTipo)
          setPeso(response.data[0].peso)
          setMaterial(response.data[0].material)
          setIdMaterial(response.data[0].idMaterial)
          setIdMarca(response.data[0].id_marcas)
          setIdModeloCabeza(response.data[0].id_modelo_cabeza)
          setIdModeloVarilla(response.data[0].id_palo)
          setIdTipoCabezas(response.data[0].tipo_cabezas)
          setIdFlex(response.data[0].idFlex)
          setLoft(response.data[0].loftCabeza)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getCabezaDriverAct = () => {
    let defaultValues = {}
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/cabeza-driver-actual-get`, {
        params: { id },
      })
      .then((response) => {
        if (response != 'vacio') {
          setIdCabezaDriverActual(response.data[0].id)
          defaultValues.id_cabeza_driver_act = response.data[0].id
          defaultValues.distanciaAct = response.data[0].distancia
          defaultValues.angSalidaAct = response.data[0].angSalida
          defaultValues.spinAct = response.data[0].spin
          defaultValues.impactoAct = response.data[0].factImpacto
          defaultValues.bolaAct = response.data[0].velBola
          defaultValues.paloAct = response.data[0].velPalo
          defaultValues.trayectoriaAct = response.data[0].trayectoria
          setOkActual(response.data[0].ok)
          reset({ ...defaultValues })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getCabezaDriverAct2 = () => {
    let defaultValues = {}
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/cabeza-driver-actual-get-2`, {
        params: { id },
      })
      .then((response) => {
        if (response != 'vacio') {
          setIdCabezaDriverActual2(response.data[0].id)
          defaultValues.id_cabeza_driver_act_2 = response.data[0].id
          defaultValues.distanciaAct2 = response.data[0].distancia
          defaultValues.angSalidaAct2 = response.data[0].angSalida
          defaultValues.spinAct2 = response.data[0].spin
          defaultValues.impactoAct2 = response.data[0].factImpacto
          defaultValues.bolaAct2 = response.data[0].velBola
          defaultValues.paloAct2 = response.data[0].velPalo
          defaultValues.trayectoriaAct2 = response.data[0].trayectoria
          setOkActual2(response.data[0].ok)
          reset({ ...defaultValues })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getCabezaDriver()
    getVarillasHierrosAct()
    getCabezaDriverAct()
    getCabezaDriverAct2()
  }, [])

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' })

  const getCabezaDriverId = (id) => {
    let defaultValues = {}
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/cabeza-driver-id-get`, {
        params: { id },
      })
      .then((response) => {
        if (response != 'vacio') {
          setIdCabezaDriverAct(response.data[0].id)
          defaultValues.id_cabeza_driver = response.data[0].id
          defaultValues.id_informe_tecnico = response.data[0].id_informe_tecnico
          defaultValues.id_marca_cabeza_driver = response.data[0].id_marca_cabeza_driver
          defaultValues.id_modelo_cabeza = response.data[0].id_modelo_cabeza
          defaultValues.id_tipo_cabeza = response.data[0].id_tipo_cabeza
          defaultValues.id_flex = response.data[0].id_flex
          defaultValues.peso = response.data[0].peso
          defaultValues.id_material = response.data[0].id_materia
          defaultValues.trayectoria = response.data[0].trayectoria
          defaultValues.loftCabeza = response.data[0].loftCabeza
          defaultValues.distancia = response.data[0].distancia
          defaultValues.angSalida = response.data[0].angSalida
          defaultValues.spin = response.data[0].spin
          defaultValues.factImpacto = response.data[0].factImpacto
          defaultValues.velBola = response.data[0].velBola
          defaultValues.velPalo = response.data[0].velPalo
          reset({ ...defaultValues })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function clearFields() {
    let defaultValues = {}
    defaultValues.id_cabeza_driver = ''
    defaultValues.id_marca_cabeza_driver = ''
    defaultValues.id_modelo_cabeza = ''
    defaultValues.id_tipo_cabeza = ''
    defaultValues.loftCabeza = ''
    defaultValues.distancia = ''
    defaultValues.angSalida = ''
    defaultValues.spin = ''
    defaultValues.factImpacto = ''
    defaultValues.velBola = ''
    defaultValues.velPalo = ''
    defaultValues.id_flex = ''
    defaultValues.peso = ''
    defaultValues.id_material = ''
    defaultValues.trayectoria = ''
    reset({ ...defaultValues })
  }

  const update = (id, idInf) => {
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/cabeza-driver-ok`, {
        id: id,
        idInf: idInf,
      })
      .then((res) => {
        getCabezaDriver()
        getCabezaDriverAct()
        getCabezaDriverAct2()
        Swal.fire({
          html: '<i>Process complete!</i>',
          icon: 'success',
          timer: 1000,
        })
        window.location.reload();
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const driverActual = (datos) => {
    if (idCabezaDriverActual) {
      axios
        .put(`${process.env.REACT_APP_BASE_URL}/cabezas-driver-actual-upd`, {
          idMarca: idMarca,
          idModeloCabeza: idModeloCabeza,
          idModeloVarilla: idModeloVarilla,
          idTipoCabezas: idTipoCabezas,
          idFlex: idFlex,
          peso: peso,
          idMaterial: idMaterial,
          loft: loft,
          data: datos,
        })
        .then((res) => {
          Swal.fire({
            html: '<i>Process complete!</i>',
            icon: 'success',
            timer: 1000,
          })
          getCabezaDriverAct()
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/driver-actual-ins`, {
          idMarca: idMarca,
          idModeloCabeza: idModeloCabeza,
          idModeloVarilla: idModeloVarilla,
          idTipoCabezas: idTipoCabezas,
          idFlex: idFlex,
          peso: peso,
          idMaterial: idMaterial,
          loft: loft,
          data: datos,
        })
        .then((res) => {
          Swal.fire({
            html: '<i>Process complete!</i>',
            icon: 'success',
            timer: 1000,
          })
          getCabezaDriverAct()
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const driverActual2 = (datos2) => {
    if (idCabezaDriverActual2) {
      axios
        .put(`${process.env.REACT_APP_BASE_URL}/cabezas-driver-actual-upd-2`, {
          data: datos2,
        })
        .then((res) => {
          Swal.fire({
            html: '<i>Process complete!</i>',
            icon: 'success',
            timer: 1000,
          })
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/driver-actual-ins-2`, {
          idMarca: idMarca,
          idModeloCabeza: idModeloCabeza,
          idModeloVarilla: idModeloVarilla,
          idTipoCabezas: idTipoCabezas,
          idFlex: idFlex,
          peso: peso,
          idMaterial: idMaterial,
          data: datos2,
        })
        .then((res) => {
          Swal.fire({
            html: '<i>Process complete!</i>',
            icon: 'success',
            timer: 1000,
          })
          getCabezaDriverAct()
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const onSubmit = (data) => {
    if (idCabezaDriverAct) {
      axios
        .put(`${process.env.REACT_APP_BASE_URL}/cabeza-driver-upd`, {
          data: data,
        })
        .then((res) => {
          Swal.fire({
            html: '<i>Process complete!</i>',
            icon: 'success',
            timer: 1000,
          })
          clearFields()
          getCabezaDriver()
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/cabeza-driver-ins`, {
          data: data,
        })
        .then((res) => {
          Swal.fire({
            html: '<i>Process complete!</i>',
            icon: 'success',
            timer: 1000,
          })
          clearFields()
          getCabezaDriver()
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const deleteID = (id) => {
    Swal.fire({
      title: 'Estas seguro de eliminar este registro?',
      text: 'No podrás revertir esto.!',
      icon: 'Cuidado',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/cabeza-driver-del/${id}`)
          .then((res) => {
            Swal.fire({
              title: 'Eliminado!',
              icon: 'success',
              time: 1000,
            })
            getCabezaDriver()
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
  }
  return (
    <Accordion.Item eventKey="6" className="mt-4">
      <Accordion.Button
        variant="link"
        className="w-100 d-flex justify-content-between accordion-button-info"
      >
        <span className="h6 mb-0 fw-bold">Selección de Driver</span>
      </Accordion.Button>
      <Accordion.Body className="table-bording">
        <div className="row">
          <h5 className="my-4">Driver Actual</h5>
          <Form onSubmit={handleSubmit(driverActual)}>
            <input type="hidden" {...register('id_cabeza_driver_act')} />
            <input
              type="hidden"
              value={id}
              {...register('id_informe_tecnico')}
            />
            <table className="table table_class_informe">
              <thead>
                <tr>
                  <th></th>
                  <th scope="col" className='text-center'>Marca de cabeza</th>
                  <th scope="col" className='text-center'>Modelo de cabeza</th>
                  <th scope="col" className='text-center'>Tipo de cabeza</th>
                  <th scope="col" className='text-center'>Modelo de varilla</th>
                  <th scope="col" className='text-center'>Flex</th>
                  <th scope="col" className='text-center'>Peso</th>
                  <th scope="col" className='text-center'>Material</th>
                  <th scope="col" className='text-center'>Distancia (yds)</th>
                  <th scope="col" className='text-center'>Ang. Salida (°)</th>
                  <th scope="col" className='text-center'>Spin (rpm)</th>
                  <th scope="col" className='text-center'>Fact Impacto (ind)</th>
                  <th scope="col" className='text-center'>Vel. Bola (mph)</th>
                  <th scope="col" className='text-center'>Vel Palo (mph)</th>
                  <th scope="col" className='text-center'>Trayectoria</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr className="seleccion">
                  <td className="textCenter">
                    <input
                      type="radio"
                      value=""
                      checked={okActual}
                      name="ban"
                      id="banNo"
                      onChange={() => {
                        update(idCabezaDriverActual, id)
                      }}
                    />
                  </td>
                  <td className='text-center'>{idMarca}</td>
                  <td className='text-center'>{idModeloCabeza}</td>
                  <td className='text-center'>{tipoCabeza}</td>
                  <td className='text-center'>{idModeloVarilla}</td>
                  <td className='text-center'>{flex}</td>
                  <td className='text-center'>{peso}</td>
                  <td className='text-center'>{material}</td>
                  <td>
                    {' '}
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Distancia (yds)"
                        {...register('distanciaAct')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    {' '}
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Ang. Salida (°)"
                        {...register('angSalidaAct')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    {' '}
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Spin (rpm)"
                        {...register('spinAct')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    {' '}
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Fact Impacto (ind)"
                        {...register('impactoAct')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    {' '}
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Vel. Bola (mph)"
                        {...register('bolaAct')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Vel Palo (mph)"
                        {...register('paloAct')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group id="gender">
                      <Form.Select
                        defaultValue="0"
                        {...register('trayectoriaAct')}
                      >
                        <option value="">Sel. Trayectoría</option>
                        <option value="Straight">Straight</option>
                        <option value="Slice">Slice</option>
                        <option value="Hook">Hook</option>
                        <option value="Draw">Draw</option>
                        <option value="Fade">Fade</option>
                        <option value="Push">Push</option>
                        <option value="Pull">Pull</option>
                        <option value="Push slice">Push slice</option>
                        <option value="Pull Hook">Pull Hook</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <button
                      type="submit"
                      className="btn btn-success btn-sm mt-2"
                    >
                      Guardar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </Form>
          {/* <Form onSubmit={handleSubmit(driverActual2)}>
            <input type="hidden" {...register('id_cabeza_driver_act_2')} />
            <input
              type="hidden"
              value={id}
              {...register('id_informe_tecnico')}
            />
            <table className="table table_class_informe">
              <thead>
                <tr>
                  <th></th>
                  <th scope="col" className='text-center'>Marca de cabeza</th>
                  <th scope="col" className='text-center'>Modelo de cabeza</th>
                  <th scope="col" className='text-center'>Tipo de cabeza</th>
                  <th scope="col" className='text-center'>Modelo de varilla</th>
                  <th scope="col" className='text-center'>Flex</th>
                  <th scope="col" className='text-center'>Peso</th>
                  <th scope="col" className='text-center'>Material</th>
                  <th scope="col" className='text-center'>Distancia (yds)</th>
                  <th scope="col" className='text-center'>Ang. Salida (°)</th>
                  <th scope="col" className='text-center'>Spin (rpm)</th>
                  <th scope="col" className='text-center'>Fact Impacto (ind)</th>
                  <th scope="col" className='text-center'>Vel. Bola (mph)</th>
                  <th scope="col" className='text-center'>Vel Palo (mph)</th>
                  <th scope="col" className='text-center'>Trayectoria</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr className="seleccion">
                  <td className="textCenter">
                    <input
                      type="radio"
                      value=""
                      checked={okActual2}
                      name="ban"
                      id="banNo"
                      onChange={() => {
                        update(idCabezaDriverActual2, id)
                      }}
                    />
                  </td>
                  <td className='text-center'>{idMarca}</td>
                  <td className='text-center'>{idModeloCabeza}</td>
                  <td className='text-center'>{tipoCabeza}</td>
                  <td className='text-center'>{idModeloVarilla}</td>
                  <td className='text-center'>{flex}</td>
                  <td className='text-center'>{peso}</td>
                  <td className='text-center'>{material}</td>
                  <td>
                    {' '}
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Distancia (yds)"
                        {...register('distanciaAct2')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    {' '}
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Ang. Salida (°)"
                        {...register('angSalidaAct2')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    {' '}
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Spin (rpm)"
                        {...register('spinAct2')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    {' '}
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Fact Impacto (ind)"
                        {...register('impactoAct2')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    {' '}
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Vel. Bola (mph)"
                        {...register('bolaAct2')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Vel Palo (mph)"
                        {...register('paloAct2')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group id="gender">
                      <Form.Select
                        defaultValue="0"
                        {...register('trayectoriaAct2')}
                      >
                        <option value="">Sel. Trayectoría</option>
                        <option value="Straight">Straight</option>
                        <option value="Slice">Slice</option>
                        <option value="Hook">Hook</option>
                        <option value="Draw">Draw</option>
                        <option value="Fade">Fade</option>
                        <option value="Push">Push</option>
                        <option value="Pull">Pull</option>
                        <option value="Push slice">Push slice</option>
                        <option value="Pull Hook">Pull Hook</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <button
                      type="submit"
                      className="btn btn-success btn-sm mt-2"
                    >
                      Guardar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </Form> */}
          <h5 className="my-4">Add Driver</h5>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <table className="table var_form_table_class_informe">
              <thead>
                <tr>
                  <th scope="col">Marca de cabeza</th>
                  <th scope="col">Modelo de cabeza</th>
                  <th scope="col">Tipo de cabeza</th>
                  <th scope="col">Modelo de varilla</th>
                  <th scope="col">Flex</th>
                  <th scope="col">Material</th>
                  <th scope="col">Peso</th>
                  <th scope="col">Distancia (yds)</th>
                  <th scope="col">Ang. Salida (°)</th>
                  <th scope="col">Spin (rpm)</th>
                  <th scope="col">Fact Impacto (ind)</th>
                  <th scope="col">Vel. Bola (mph)</th>
                  <th scope="col">Vel Palo (mph)</th>
                  <th scope="col">Trayectoria</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input type="hidden" {...register('id_cabeza_driver')} />
                    <input
                      type="hidden"
                      value={id}
                      {...register('id_informe_tecnico')}
                    />
                    <Form.Group>
                      <Form.Select
                        defaultValue="0"
                        {...register('id_marca_cabeza_driver')}
                      >
                        <option value="">Sel. Marca cabeza</option>
                        <ListName url="marca-varilla-get" />
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Select
                        defaultValue="0"
                        {...register('id_modelo_cabeza')}
                      >
                        <option value="">Sel. Modelo cabeza</option>
                        <ListName url="modelo-cabezas-get" />
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Select
                        defaultValue="0"
                        {...register('id_tipo_cabeza')}
                      >
                        <option value="">Sel. Tipo cabeza</option>
                        <ListName url="tipos-cabeza-get" />
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Select defaultValue="0" {...register('loftCabeza')}>
                        <option value="">Sel. Modelo varilla</option>
                        <ListName url="driver-all-get" />
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Select defaultValue="0" {...register('id_flex')}>
                        <option value="">Sel. Flex</option>
                        <ListName url="flex-varilla-get" />
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Select defaultValue="0" {...register('id_material')}>
                        <option value="#">Sel. Material</option>
                        <ListName url="material-get" />
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Select
                        defaultValue="0"
                        {...register('peso')}
                      >
                        <option value="">Sel. peso</option>
                        <option value="40 gr">40 gr</option>
                        <option value="50 gr">50 gr</option>
                        <option value="60 gr">60 gr</option>
                        <option value="70 gr">70 gr</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="distancia"
                        {...register('distancia')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="angSalida"
                        {...register('angSalida')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="spin"
                        {...register('spin')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="factImpacto"
                        {...register('factImpacto')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="velBola"
                        {...register('velBola')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="velPalo"
                        {...register('velPalo')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group id="gender">
                      <Form.Select
                        defaultValue="0"
                        {...register('trayectoria')}
                      >
                        <option value="">Sel. Trayectoría</option>
                        <option value="Straight">Straight</option>
                        <option value="Slice">Slice</option>
                        <option value="Hook">Hook</option>
                        <option value="Draw">Draw</option>
                        <option value="Fade">Fade</option>
                        <option value="Push">Push</option>
                        <option value="Pull">Pull</option>
                        <option value="Push slice">Push slice</option>
                        <option value="Pull Hook">Pull Hook</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <button
                      type="submit"
                      className="btn btn-success btn-sm mt-2"
                    >
                      Guardar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </Form>
          {idCabezaDriver >= 0 && (
            <>
              <h5 className="my-4">Cabezas Driver</h5>
              <table className="table table_class_informe">
                <thead>
                  <tr>
                    <th scope="col" className='text-center'></th>
                    <th scope="col" className='text-center'>Marca de cabeza</th>
                    <th scope="col" className='text-center'>Modelo de cabeza</th>
                    <th scope="col" className='text-center'>Tipo de cabeza</th>
                    <th scope="col" className='text-center'>Modelo de varilla</th>
                    <th scope="col" className='text-center'>Flex</th>
                    <th scope="col" className='text-center'>Material</th>
                    <th scope="col" className='text-center'>Peso</th>
                    <th scope="col" className='text-center'>Distancia (yds)</th>
                    <th scope="col" className='text-center'>Ang. Salida (°)</th>
                    <th scope="col" className='text-center'>Spin (rpm)</th>
                    <th scope="col" className='text-center'>Fact Impacto (ind)</th>
                    <th scope="col" className='text-center'>Vel. Bola (mph)</th>
                    <th scope="col" className='text-center'>Vel Palo (mph)</th>
                    <th scope="col" className='text-center'>Trayectoria</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(tablaCabezaDriver) && tablaCabezaDriver.map((val, key) => {
                    return (
                      <tr key={key}>
                        <td className="textCenter">
                          <input
                            type="radio"
                            value=""
                            checked={val.ok}
                            name="ban"
                            id="banNo"
                            onClick={() => {
                              update(val.id, val.id_informe_tecnico)
                            }}
                          />
                        </td>
                        <td className='text-center'>{val.namMarca}</td>
                        <td className='text-center'>{val.nomModelo}</td>
                        <td className='text-center'>{val.namtipo}</td>
                        <td className='text-center'>{val.nomModVarilla}</td>
                        <td className='text-center'>{val.namFlex}</td>
                        <td className='text-center'>{val.material}</td>
                        <td className='text-center'>{val.peso}</td>
                        <td className='text-center'>{val.distancia}</td>
                        <td className='text-center'>{val.angSalida}</td>
                        <td className='text-center'>{val.spin}</td>
                        <td className='text-center'>{val.factImpacto}</td>
                        <td className='text-center'>{val.velBola}</td>
                        <td className='text-center'>{val.velPalo}</td>
                        <td className='text-center'>{val.trayectoria}</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              getCabezaDriverId(val.id)
                            }}
                          >
                            Actualizar
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm mx-2"
                            onClick={() => {
                              deleteID(val.id)
                            }}
                          >
                            Eliminar
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}
