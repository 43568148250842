import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { Container, Row, Col, Card, Spinner, Alert, Tabs, Tab } from '@themesberg/react-bootstrap';
import { FaBoxOpen } from 'react-icons/fa';

const ProductCard = ({ image, title, description, price, link }) => (
  <Card className="mb-3 shadow-sm product-card" style={{ minHeight: '350px', border: '1px solid #ddd' }}>
    <div className="image-container">
      <Card.Img variant="top" src={image} className="card-image" />
    </div>
    <Card.Body>
      <Card.Title>{title}</Card.Title>
      <Card.Text>{description}</Card.Text>
      <h5>{price}</h5>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <button type="button" className="btn btn-primary btn-sm">Ver</button>
      </a>
    </Card.Body>
  </Card>
);

const ProductPage = () => {
  const { categoryId, idFitting } = useParams();
  const [products, setProducts] = useState([]);
  const [relatedModels, setRelatedModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [attributes, setAttributes] = useState('');
  const [activeTab, setActiveTab] = useState('new');

  const endpoints = {
    new: {
      1: '/madera-clientes',
      2: '/madera-clientes',
      3: '/madera-clientes',
      4: '/hierros-clientes',
      5: '/wedges-clientes',
      6: '/wedges-clientes',
      37: '/putts-clientes',
    },
    secondHand: {
      1: '/usados-material',
      2: '/usados-maderas-material',
      3: '/usados-hibridos-material',
      4: '/usados-hierro-material',
      5: '/usados-wedge-material',
      6: '/usados-iron-material',
      37: '/putts-segunda-mano',
    }
  };

  const categoryType = {
    new: {
      '1': 'DRIVER',
      '2': 'MADERAS',
      '3': 'HIBRIDOS',
      '4': 'HIERROS',
      '5': 'WEDGES',
      '6': 'IRON DRIVING',
      '37': 'PUTTS'
    },
    secondHand: {
      '1': 'DRIVER - Usado',
      '2': 'MADERA - Usado',
      '3': 'HIBRIDO - Usado',
      '4': 'HIERROS - Usado',
      '5': 'WEDGE - Usado',
      '6': 'IRON DRIVING - Usado',
      '37': 'PUTTER - Usado'
    }
    ,
  };

  const categoryName = categoryType[activeTab][categoryId] || "Categoría Desconocida";

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        setError(null);
        setProducts([]);
        setRelatedModels([]);

        const url = endpoints[activeTab][categoryId];
        const type = categoryType[activeTab][categoryId];

        if (!url) {
          throw new Error('Categoría no válida');
        }

        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}${url}`, {
          params: { idFitting: idFitting, type: type, categoryId: categoryId }
        });

        setProducts(response.data.drivers || []);
        setRelatedModels(response.data.relatedModels || []);

        if (response.data.drivers && response.data.drivers.length > 0) {
          let manejo = "";
          const firstDriver = response.data.drivers[0];
          const flex = firstDriver.flex_name ? `?varilla_flex=${firstDriver.flex_name}` : '';
          if (categoryId == 37) {
            manejo = firstDriver.diestroZurdo ? `?manejo=${firstDriver.diestroZurdo}` : '';
          }else{
            manejo = firstDriver.diestroZurdo ? `&manejo=${firstDriver.diestroZurdo}` : '';
          }
          const talla = firstDriver.talla ? `&varilla_talla=${firstDriver.talla}` : '';
          const loft = firstDriver.loft ? `&loft=${firstDriver.loft}` : '';
          const grip = firstDriver.grip ? `&varilla_grip=${firstDriver.grip}` : '';
          const gripReferencia = firstDriver.grip_referencia ? `&varilla_referencia_grip=${firstDriver.grip_referencia}` : '';
          const peso = firstDriver.peso ? `&varilla_peso=${firstDriver.peso}` : '';
          let attributes = `${flex}${manejo}${talla}${loft}${grip}${gripReferencia}${peso}`;

          if ([4, 5, 6].includes(categoryId) && firstDriver.material) {
            attributes += `&material=${firstDriver.material}`;
          }

          if (categoryId == 4) {
            if (firstDriver.w3 == "1") attributes += `&varilla_num[]=3`;
            if (firstDriver.w4 == "1") attributes += `&varilla_num[]=4`;
            if (firstDriver.w5 == "1") attributes += `&varilla_num[]=5`;
            if (firstDriver.w6 == "1") attributes += `&varilla_num[]=6`;
            if (firstDriver.w7 == "1") attributes += `&varilla_num[]=7`;
            if (firstDriver.w8 == "1") attributes += `&varilla_num[]=8`;
            if (firstDriver.w9 == "1") attributes += `&varilla_num[]=9`;
            if (firstDriver.pw == "1") attributes += `&varilla_num[]=PW`;
          }

          if (categoryId == 3) {
            if (firstDriver.HYB2 == "1") attributes += `&hibrido_num[]=HYB 2`;
            if (firstDriver.HYB3 == "1") attributes += `&hibrido_num[]=HYB 3`;
            if (firstDriver.HYB4 == "1") attributes += `&hibrido_num[]=HYB 4`;
            if (firstDriver.HYB5 == "1") attributes += `&hibrido_num[]=HYB 5`;
            if (firstDriver.HYB6 == "1") attributes += `&hibrido_num[]=HYB 6`;
          }

          if (categoryId == 5) {
            if (firstDriver.wed == "1") attributes += `&wedge_num[]=PW 43°`;
            if (firstDriver.wed2 == "1") attributes += `&wedge_num[]=PW 48°`;
            if (firstDriver.wed3 == "1") attributes += `&wedge_num[]=AW 50°`;
            if (firstDriver.wed4 == "1") attributes += `&wedge_num[]=AW 52°`;
            if (firstDriver.wed5 == "1") attributes += `&wedge_num[]=SW 54°`;
            if (firstDriver.wed6 == "1") attributes += `&wedge_num[]=SW 56°`;
            if (firstDriver.wed7 == "1") attributes += `&wedge_num[]=LW 58°`;
            if (firstDriver.wed8 == "1") attributes += `&wedge_num[]=LW 60°`;
            if (firstDriver.wed9 == "1") attributes += `&wedge_num[]=LW 62°`;
            if (firstDriver.wed10 == "1") attributes += `&wedge_num[]=LW 64°`;
          }

          if (categoryId == 6) {
            if (firstDriver.id2 == "1") attributes += `&iron_num[]=ID 2`;
            if (firstDriver.id3 == "1") attributes += `&iron_num[]=ID 3`;
            if (firstDriver.id4 == "1") attributes += `&iron_num[]=ID 4`;
            if (firstDriver.id5 == "1") attributes += `&iron_num[]=ID 5`;
          }

          setAttributes(attributes);
        }

        setLoading(false);
      } catch (err) {
        setError('Hubo un error al obtener los productos.');
        console.error(err);
        setLoading(false);
      }
    };

    if (categoryId && idFitting) {
      fetchProducts();
    }
  }, [categoryId, idFitting, activeTab]);

  const generateProductLink = (product, useAttributes = true) => {
    const baseUrl = `https://rccolombiangolf.com/producto/${product.post_name}`;
    return useAttributes ? `${baseUrl}${attributes}` : baseUrl;
  };

  return (
    <Container className="mt-5">
      <h3>Productos de {categoryName}</h3>
      <Tabs
        id="product-tabs"
        activeKey={activeTab}
        onSelect={(tab) => setActiveTab(tab)}
        className="mb-3"
      >
        <Tab eventKey="new" title="Nuevos">
          {loading ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Cargando...</span>
            </Spinner>
          ) : error ? (
            <Alert variant="danger">{error}</Alert>
          ) : (products.length > 0 || relatedModels.length > 0) ? (
            <Row>
              {products.map(product => (
                <Col key={product.id} sm={6} md={4} lg={3} className="mb-4">
                  <ProductCard
                    image={product.image}
                    title={product.name}
                    description={product.description}
                    price={product.price}
                    link={generateProductLink(product, true)}
                  />
                </Col>
              ))}
              {relatedModels.map(model => (
                <Col key={model.id} sm={6} md={4} lg={3} className="mb-4">
                  <ProductCard
                    image={model.image}
                    title={model.name}
                    description={model.description}
                    price={model.price}
                    link={generateProductLink(model, true)}
                  />
                </Col>
              ))}
            </Row>
          ) : (
            <Alert variant="info">
              <FaBoxOpen size={40} className="me-3" />
              No se encontraron productos para esta categoría.
            </Alert>
          )}
        </Tab>

        <Tab eventKey="secondHand" title="Usados">
          {loading ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Cargando...</span>
            </Spinner>
          ) : error ? (
            <Alert variant="danger">{error}</Alert>
          ) : (products.length > 0 || relatedModels.length > 0) ? (
            <Row>
              {products.map(product => (
                <Col key={product.id} sm={6} md={4} lg={3} className="mb-4">
                  <ProductCard
                    image={product.image}
                    title={product.id_modelo_cabeza}
                    description={product.description}
                    price={product.price}
                    link={generateProductLink(product, true)}
                  />
                </Col>
              ))}
              {relatedModels.map(model => (
                <Col key={model.id} sm={6} md={4} lg={3} className="mb-4">
                  <ProductCard
                    image={model.image}
                    title={model.id_modelo_cabeza}
                    description={model.description}
                    price={model.price}
                    link={generateProductLink(model, true)}
                  />
                </Col>
              ))}
            </Row>
          ) : (
            <Alert variant="info">
              <FaBoxOpen size={40} className="me-3" />
              No se encontraron productos para esta categoría.
            </Alert>
          )}
        </Tab>
      </Tabs>
    </Container>
  );
};

export default ProductPage;
