import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { ListName } from './functions-listas-select'
import AutoComplete from './functions-autocomplete-ide-actual'
import {
  Col,
  Modal,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from '@themesberg/react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import debounce from 'lodash/debounce';

const InformeActualizar = () => {

  const [users, setUsers] = useState([]);
  const [modelosCab, setModCabezas] = useState([]);
  const [marcaPalos, setMarcaPalos] = useState([]);
  const [tiposCabezas, setTiposCabezas] = useState([]);
  const [modelosVarillas, setModelosVarillas] = useState([]);

  const [idEquActual, setIdEquActual] = useState(0)
  const [hierros, setHierros] = useState(0)
  const [numCabeza, setNumCabeza] = useState(0)
  const [putter, setPutter] = useState(0)
  const [archivos, setArchivos] = useState(0)
  const [idTipoPalo, setIdTipoPalo] = useState(0);
  const [updPublicado, setUpdPublicado] = useState(0);

  const MarcaPalos = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/marca-varilla-get`)
      .then((response) => {
        setMarcaPalos(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const modeloCabezas = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/modelo-cabeza-get`)
      .then((response) => {
        setModCabezas(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const tipoCabezas = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/tipos-cabeza-get`)
      .then((response) => {
        setTiposCabezas(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const modelosVarilla = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/modelo-varilla-get`)
      .then((response) => {
        setModelosVarillas(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const { id } = useParams();
  const {
    register,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' })



  const getIdEquActualId = (id) => {
    let defaultValues = {}
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/identificacion-actual-id-get`, {
        params: { id },
      })
      .then((response) => {
        if (response != 'vacio') {
          setIdEquActual(response.data[0].id_informe_tecnico)
          defaultValues.id = response.data[0].id
          defaultValues.id_tipo_palos = response.data[0].id_tipo_palos
          tipoPalo(response.data[0].id_tipo_palos)
          setPutter(response.data[0].id_tipo_palos)
          defaultValues.desde = response.data[0].desde
          setHierros(response.data[0].desde)
          defaultValues.hasta = response.data[0].hasta
          defaultValues.id_marcas = response.data[0].id_marcas
          defaultValues.id_modelo_cabeza = response.data[0].id_modelo_cabeza
          defaultValues.id_tipo_cabeza = response.data[0].id_tipo_cabeza
          defaultValues.numero_cabeza = response.data[0].numero_cabeza
          defaultValues.loftCabeza = response.data[0].loftCabeza
          defaultValues.id_palo = response.data[0].id_palo
          defaultValues.fabrica = response.data[0].fabrica
          defaultValues.material = response.data[0].material
          defaultValues.flex = response.data[0].flex
          defaultValues.peso = response.data[0].peso
          defaultValues.estadoEquipo = response.data[0].estadoEquipo
          defaultValues.precio = response.data[0].precio
          setUpdPublicado(response.data[0].upd_publicado)
          reset({ ...defaultValues })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getIdEquActualId(id)
  }, [id])

  const tipoPalo = (idTipoPalo) => {
    const id = parseInt(idTipoPalo, 10);
    setIdTipoPalo(id);
    if (idTipoPalo == 4) {
      setHierros(1)
    } else {
      setHierros(0)
    }
    if (idTipoPalo == 1 || idTipoPalo == 4 || idTipoPalo == 37) {
      setNumCabeza(0)
    } else {
      setNumCabeza(1)
    }
    if (idTipoPalo == 37) {
      setPutter(1)
    } else {
      setPutter(0)
    }


  }


  const onSubmit = async (data) => {
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/identificacion-actual-upd`, {
        data: data,
      })
      .then((res) => {
        Swal.fire({
          title: 'Actualizado!',
          icon: 'success',
          time: 1000,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" value={id} {...register('id')} />
        <input type="hidden" value={updPublicado} {...register('upd_publicado')} />
        <Row>
          <h5 className="my-4">Actualizar Información del equipo actual</h5>
          <Col md={4} className="mb-3">
            <Form.Group id="gender">
              <Form.Label>Tipo de Palo</Form.Label>
              <Form.Select disabled defaultValue="0" {...register('id_tipo_palos')} onChange={(e) => { tipoPalo(e.target.value) }}>
                <ListName url="tipo-palos" />
              </Form.Select>
            </Form.Group>
          </Col>
          {hierros > 0 && (
            <>
              <Col md={4} className="mb-3">
                <Form.Group>
                  <Form.Label>Desde</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Desde"
                    {...register('desde')}
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group>
                  <Form.Label>Hasta</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Hasta"
                    {...register('hasta')}
                  />
                </Form.Group>
              </Col>
            </>
          )}
          <Col md={4} className="mb-3">
            <Form.Group id="marca-aplos">
              <Form.Label>Marca Palos</Form.Label>
              <Form.Control
                type="text"
                placeholder="Marca Palos"
                {...register('id_marcas')}
              />
            </Form.Group>
            {/* <Form.Group id="marca-aplos">
              <Form.Label>Marca Palos</Form.Label>
              <input
                id="marcaPalo"
                type="text"
                placeholder='Marca Palo'
                className="form-control"
                list="marcaPalos"
                {...register('id_marcas')}
                onChange={debounce(MarcaPalos, 300)}
              // onInput={e => setMarcaPalo(e.target.value)}
              />
              <datalist id="marcaPalos">
                {marcaPalos.map((marcaP) => (
                  <option class="list" value={marcaP.name} key={marcaP.id} >
                    {marcaP.name}
                  </option>
                ))}
              </datalist>
            </Form.Group> */}
          </Col>
          <Col md={4} className="mb-3">
            <Form.Group id="marca-aplos">
              <Form.Label>Modelos Cabezas</Form.Label>
              <Form.Control
                type="text"
                placeholder="Modelos Cabezas"
                {...register('id_modelo_cabeza')}
              />
            </Form.Group>
            {/* <Form.Group id="marca-aplos">
              <Form.Label>Modelos Cabezas</Form.Label>
              <input
                id="marcaCabeza"
                type="text"
                placeholder='Modelos Cabeza'
                className="form-control"
                list="modelosCabeza"
                {...register('id_modelo_cabeza')}
                onChange={debounce(modeloCabezas, 300)}
              // onInput={e => setModelosCabeza(e.target.value)}
              />
              <datalist id="modelosCabeza">
                {modelosCab.map((modCabeza) => (
                  <option class="list" value={modCabeza.name} key={modCabeza.id} >
                    {modCabeza.name}
                  </option>
                ))}
              </datalist>
            </Form.Group> */}
          </Col>
          <Col md={4} className="mb-3">
            <Form.Group id="tipo-cabeza">
              <Form.Label>Tipo Cabezas</Form.Label>
              <Form.Select defaultValue="0" {...register('id_tipo_cabeza')}>
                <ListName url="tipos-cabeza-get" />
              </Form.Select>
            </Form.Group>
            {/* <Form.Group id="marca-aplos">
              <Form.Label>Tipo Cabezas</Form.Label>
              <input
                id="tipoCabeza"
                type="text"
                placeholder='Tipos Cabeza'
                className="form-control"
                list="tiposCabeza"
                {...register('id_tipo_cabeza')}
                onChange={debounce(tipoCabezas, 300)}
              // onInput={e => setTipoCabeza(e.target.value)}
              />
              <datalist id="tiposCabeza">
                {tiposCabezas.map((tipoCabeza) => (
                  <option class="list" value={tipoCabeza.name} key={tipoCabeza.id} >
                    {tipoCabeza.name}
                  </option>
                ))}
              </datalist>
            </Form.Group> */}
          </Col>
          {putter != 37 && (
            <>
              <Col md={4} className="mb-3">
                <Form.Group id="phone">
                  <Form.Label>Número de cabeza </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="número de cabeza"
                    {...register('numero_cabeza')}
                  />
                </Form.Group>
              </Col>
              {idTipoPalo !== 37 && (
                <Col md={4} className="mb-3">
                  <Form.Group id="loftCabeza">
                    <Form.Label>Loft Cabeza</Form.Label>
                    <Form.Select defaultValue="0" {...register('loftCabeza')}>
                      <option value="">Sel. Loft</option>
                      {idTipoPalo === 1 && (
                        <>
                          <option value="8.0°">8.0°</option>
                          <option value="8.5°">8.5°</option>
                          <option value="9.0°">9.0°</option>
                          <option value="9.5°">9.5°</option>
                          <option value="10°">10°</option>
                          <option value="10.5°">10.5°</option>
                          <option value="11°">11°</option>
                          <option value="12°">12°</option>
                        </>
                      )}
                      {idTipoPalo === 2 && (
                        <>
                          <option value="W3">W3</option>
                          <option value="W5">W5</option>
                          <option value="W7">W7</option>
                          <option value="W9">W9</option>
                        </>
                      )}
                      {idTipoPalo === 3 && (
                        <>
                          <option value="HYB3">HYB3</option>
                          <option value="HYB4">HYB4</option>
                          <option value="HYB5">HYB5</option>
                          <option value="HYB6">HYB6</option>
                        </>
                      )}
                      {idTipoPalo === 4 && (
                        <>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="30.5">30.5</option>
                          <option value="33">33</option>
                          <option value="34">34</option>
                        </>
                      )}
                      {idTipoPalo === 5 && (
                        <>
                          <option value="PW43">PW 43</option>
                          <option value="PW48">PW 48</option>
                          <option value="AW50">AW 50</option>
                          <option value="AW52">AW 52</option>
                          <option value="SW54">SW 54</option>
                          <option value="SW56">SW 56</option>
                          <option value="LW58">LW 58</option>
                          <option value="LW60">LW 60</option>
                          <option value="LW62">LW 62</option>
                          <option value="LW64">LW 64</option>
                        </>
                      )}
                      {idTipoPalo === 6 && (
                        <>
                          <option value="ID2">ID 2</option>
                          <option value="ID3">ID 3</option>
                          <option value="ID4">ID 4</option>
                          <option value="ID5">ID 5</option>
                        </>
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
              )}
              <Col md={4} className="mb-3">
                <Form.Group id="modelo-varilla">
                  <Form.Label>Modelo Varilla</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Modelo Varilla"
                    {...register('id_palo')}
                  />
                </Form.Group>
                {/* <Form.Group id="marca-aplos">
              <Form.Label>Modelo Varilla</Form.Label>
              <input
                id="modeloVarilla"
                type="text"
                placeholder='Modelos Varilla'
                className="form-control"
                list="modelosVarilla"
                {...register('id_palo')}
                onChange={debounce(modelosVarilla, 300)}
              // onInput={e => setModeloVarilla(e.target.value)}
              />
              <datalist id="modelosVarilla">
                {modelosVarillas.map((modVarilla) => (
                  <option class="list" value={modVarilla.name} key={modVarilla.id} >
                    {modVarilla.name}
                  </option>
                ))}
              </datalist>
            </Form.Group> */}
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group id="fabrica-varilla">
                  <Form.Label>Fabrica Varilla</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Fabrica Varilla"
                    {...register('fabrica')}
                  />
                </Form.Group>
                {/* <Form.Group id="fabrica">
              <Form.Label>Fabrica</Form.Label>
              <Form.Select defaultValue="0" {...register('fabrica')}>
                <ListName url="fabricas-get" />
              </Form.Select>
            </Form.Group> */}
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group id="material">
                  <Form.Label>Material</Form.Label>
                  <Form.Select defaultValue="0" {...register('material')}>
                    <option value="">Seleccione</option>
                    <ListName url="material-get" />
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group id="flex">
                  <Form.Label>Flex</Form.Label>
                  <Form.Select defaultValue="0" {...register('flex')}>
                    <option value="">Seleccione</option>
                    <ListName url="flexs-get" />
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group id="peso">
                  <Form.Label>Peso</Form.Label>
                  <Form.Select
                    defaultValue="0"
                    {...register('peso')}
                  >
                    <option value="">Sel. peso</option>
                    <option value="30 gr">30 gr</option>
                    <option value="40 gr">40 gr</option>
                    <option value="50 gr">50 gr</option>
                    <option value="60 gr">60 gr</option>
                    <option value="70 gr">70 gr</option>
                    <option value="80 gr">80 gr</option>
                    <option value="90 gr">90 gr</option>
                    <option value="100 gr">100 gr</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </>
          )}
          <Col md={4} className="mb-3">
            <Form.Group id="gender">
              <Form.Label>Estado del equipo</Form.Label>
              <Form.Select defaultValue="0" {...register('estadoEquipo')}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4} className="mb-3">
            <Form.Group id="precio">
              <Form.Label>Precio</Form.Label>
              <Form.Control
                type="text"
                placeholder="Precio"
                {...register('precio')}
              />
            </Form.Group>
          </Col>
        </Row>
        <h5 className="my-4">Actualizar Información del equipo actual</h5>
        <div className="mt-3">
          <Button variant="success" type="submit">
            Actualizar
          </Button>
          <React.Fragment>
            <Link to={`/fitting/fitting-details/${idEquActual}`}>
              <button type="button" className="btn btn-primary" style={{ marginLeft: '1rem' }} >
                Volver
              </button>
            </Link>
          </React.Fragment>
        </div>
      </Form>
    </>
  )
}

export default InformeActualizar
