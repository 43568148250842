import React, { useState, useEffect } from 'react'
import {
  Accordion,
  Form,
} from '@themesberg/react-bootstrap'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import Swal from 'sweetalert2'
import { ListName } from './Functions/functions-listas-select'

export default (props) => {
  const id = props.id
  const [idVarillaHierros, setIdVarillaHierros] = useState(0)
  const [idVarillaHierrosAct, setIdVarillaHierrosAct] = useState(0)
  const [idCabezaHierrosActual, setIdCabezaHierrosActual] = useState(0)
  const [tablaVarillasHierros, setTablaVarillasHierros] = useState([])
  //Varilla actual
  const [modeloVarillas, setModeloVarillas] = useState('')
  const [marcaVarillas, setMarcaVarillas] = useState('')
  const [modCabeza, setModCabeza] = useState('')
  const [material, setMaterial] = useState('')
  const [flexVarillas, setFlexVarillas] = useState('')

  const [idModeloVarillas, setIdModeloVarillas] = useState('')
  const [idMarcaVarillas, setIdMarcaVarillas] = useState('')
  const [idModCabeza, setIdModCabeza] = useState('')
  const [idMaterial, setIdMaterial] = useState('')
  const [idFlexVarillas, setIdFlexVarillas] = useState('')

  const [peso, setPeso] = useState('')
  const [fabrica, setFabrica] = useState('')
  const [okActual, setOkActual] = useState(0)

  //Fin Varilla actual

  function getVarillaHierros() {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/varillas-hierros-all-get`, {
        params: { id },
      })
      .then((response) => {
        if (response != 'vacio') {
          setTablaVarillasHierros(response.data)
          setIdVarillaHierros(response.data[0].id_informe_tecnico)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function getVarillasEquActual() {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/varillas-equ-actual-get`, {
        params: { id },
      })
      .then((response) => {
        if (response != 'vacio') {
          setIdModeloVarillas(response.data[0].id_palo)
          setIdMarcaVarillas(response.data[0].id_marcas)
          setIdModCabeza(response.data[0].id_modelo_cabeza)
          setIdMaterial(response.data[0].material)
          setIdFlexVarillas(response.data[0].flex)
          setModeloVarillas(response.data[0].nomModelo)
          setMarcaVarillas(response.data[0].namMarca)
          setFlexVarillas(response.data[0].namFlex)
          setModCabeza(response.data[0].modeloCabeza)
          setPeso(response.data[0].peso)
          setFabrica(response.data[0].fabrica)
          setMaterial(response.data[0].namMaterial)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }


  const getVarillasHierrosAct = () => {
    let defaultValues = {}
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/varillas-hierros-actual-get`, {
        params: { id },
      })
      .then((response) => {
        if (response != 'vacio') {
          setIdCabezaHierrosActual(response.data[0].id)
          defaultValues.id_varilla_hierros_act = response.data[0].id
          defaultValues.distanciaAct = response.data[0].distancia
          defaultValues.angSalidaAct = response.data[0].angSalida
          defaultValues.spinAct = response.data[0].spin
          defaultValues.impactoAct = response.data[0].factImpacto
          defaultValues.bolaAct = response.data[0].velBola
          defaultValues.paloAct = response.data[0].velPalo
          defaultValues.trayectoriaAct = response.data[0].trayectoria
          setOkActual(response.data[0].ok)
          reset({ ...defaultValues })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getVarillaHierros()
    getVarillasEquActual()
    getVarillasHierrosAct()
  }, [])

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm(
    { mode: 'onBlur' }
  )

  const getVarillaHierrosId = (id) => {
    let defaultValues = {}
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/varilla-hierros-id-get`, {
        params: { id },
      })
      .then((response) => {
        if (response != 'vacio') {
          setIdVarillaHierrosAct(response.data[0].id)
          defaultValues.id_varilla_hierros = response.data[0].id
          defaultValues.id_marca = response.data[0].id_marca
          defaultValues.id_mod_cabeza = response.data[0].id_mod_cabeza
          defaultValues.id_informe_tecnico = response.data[0].id_informe_tecnico
          defaultValues.id_modelo_cabeza = response.data[0].id_modelo_cabeza
          defaultValues.id_flex = response.data[0].id_flex
          defaultValues.id_material = response.data[0].material
          defaultValues.trayectoria = response.data[0].trayectoria
          defaultValues.peso = response.data[0].peso
          defaultValues.loftCabeza = response.data[0].loftCabeza
          defaultValues.distancia = response.data[0].distancia
          defaultValues.angSalida = response.data[0].angSalida
          defaultValues.spin = response.data[0].spin
          defaultValues.factImpacto = response.data[0].factImpacto
          defaultValues.velBola = response.data[0].velBola
          defaultValues.velPalo = response.data[0].velPalo
          reset({ ...defaultValues })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function clearFields() {
    let defaultValues = {}
    defaultValues.id_varilla_hierros = ""
    defaultValues.id_marca = ""
    defaultValues.id_modelo_cabeza = ""
    defaultValues.id_mod_cabeza = ""
    defaultValues.id_flex = ""
    defaultValues.id_material = ""
    defaultValues.trayectoria = ""
    defaultValues.peso = ""
    defaultValues.loftCabeza = ""
    defaultValues.distancia = ""
    defaultValues.angSalida = ""
    defaultValues.spin = ""
    defaultValues.factImpacto = ""
    defaultValues.velBola = ""
    defaultValues.velPalo = ""
    setIdVarillaHierrosAct(0)
    reset({ ...defaultValues })
  }

  const update = (id, idInf) => {
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/varilla-hierros-ok`, {
        id: id,
        idInf: idInf
      })
      .then((res) => {
        getVarillaHierros()
        getVarillasHierrosAct()
        Swal.fire({
          html: '<i>Process complete!</i>',
          icon: 'success',
          timer: 1000,
        })
        window.location.reload();
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const varillaActual = (datos) => {
    if (idCabezaHierrosActual) {
      axios
        .put(`${process.env.REACT_APP_BASE_URL}/varillas-hierros-actual-upd`, {
          data: datos
        })
        .then((res) => {
          Swal.fire({
            html: '<i>Process complete!</i>',
            icon: 'success',
            timer: 1000,
          })
          getVarillasHierrosAct()
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/varillas-hierros-actual-ins`, {
          idModeloVarillas: idModeloVarillas,
          idModCabeza: idModCabeza,
          idMaterial: idMaterial,
          idFlexVarillas: idFlexVarillas,
          peso: peso,
          idMarcaVarillas: idMarcaVarillas,
          data: datos
        })
        .then((res) => {
          Swal.fire({
            html: '<i>Process complete!</i>',
            icon: 'success',
            timer: 1000,
          })
          getVarillasHierrosAct()
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const onSubmit = (data) => {
    if (idVarillaHierrosAct) {
      axios
        .put(`${process.env.REACT_APP_BASE_URL}/varilla-hierros-upd`, {
          data: data
        })
        .then((res) => {
          Swal.fire({
            html: '<i>Process complete!</i>',
            icon: 'success',
            timer: 1000,
          })
          clearFields()
          getVarillaHierros()
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/varilla-hierros-ins`, {
          data: data
        })
        .then((res) => {
          Swal.fire({
            html: '<i>Process complete!</i>',
            icon: 'success',
            timer: 1000,
          })
          clearFields()
          getVarillaHierros()
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const deleteID = (id) => {
    Swal.fire({
      title: 'Estas seguro de eliminar este registro?',
      text: 'No podrás revertir esto.!',
      icon: 'Cuidado',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/varilla-hierros-del/${id}`)
          .then((res) => {
            Swal.fire({
              title: 'Eliminado!',
              icon: 'success',
              time: 1000,
            })
            getVarillaHierros()
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
  }
  return (
    <Accordion.Item eventKey="5" className="mt-4">
      <Accordion.Button
        variant="link"
        className="w-100 d-flex justify-content-between accordion-button-info"
      >
        <span className="h6 mb-0 fw-bold">
          Selección de varilla para hierros
        </span>
      </Accordion.Button>
      <Accordion.Body className="table-bording">
        <div className="row">
          <h5 className="my-4">Varilla Actual</h5>
          <Form onSubmit={handleSubmit(varillaActual)}>
            <input type="hidden" {...register('id_varilla_hierros_act')} />
            <input type="hidden" value={id} {...register('id_informe_tecnico')} />
            <table className="table table_class_informe">
              <thead>
                <tr>
                  <th></th>
                  <th scope="col">Cabeza de prueba</th>
                  <th scope="col">Fabrica de varilla</th>
                  <th scope="col">Modelo varilla</th>
                  <th scope="col">Flex</th>
                  <th scope="col">Material</th>
                  <th scope="col">Peso</th>
                  <th scope="col">Distancia (yds)</th>
                  <th scope="col">Ang. Salida (°)</th>
                  <th scope="col">Spin (rpm)</th>
                  <th scope="col">Fact Impacto (ind)</th>
                  <th scope="col">Vel. Bola (mph)</th>
                  <th scope="col">Vel Palo (mph)</th>
                  <th scope="col">Trayectoria</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr className="seleccion">
                  <td className="textCenter">
                    <input type="radio" value="" checked={okActual} name="sel-var-hierros" id="banNo" onChange={() => { update(idCabezaHierrosActual, id) }} />
                  </td>
                  <td>{idModCabeza}</td>
                  <td>{fabrica}</td>
                  <td>{idModeloVarillas}</td>
                  <td>{flexVarillas}</td>
                  <td>{material}</td>
                  <td>{peso}</td>
                  <td> <Form.Group>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Distancia (yds)"
                      {...register('distanciaAct')}
                    />
                  </Form.Group></td>
                  <td> <Form.Group>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Ang. Salida (°)"
                      {...register('angSalidaAct')}
                    />
                  </Form.Group></td>
                  <td> <Form.Group>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Spin (rpm)"
                      {...register('spinAct')}
                    />
                  </Form.Group></td>
                  <td> <Form.Group>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Fact Impacto (ind)"
                      {...register('impactoAct')}
                    />
                  </Form.Group></td>
                  <td> <Form.Group>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Vel. Bola (mph)"
                      {...register('bolaAct')}
                    />
                  </Form.Group></td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Vel Palo (mph)"
                        {...register('paloAct')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group id="gender">
                      <Form.Select
                        defaultValue="0"
                        {...register('trayectoriaAct')}
                      >
                        <option value="">Sel. Trayectoría</option>
                        <option value="Straight">Straight</option>
                        <option value="Slice">Slice</option>
                        <option value="Hook">Hook</option>
                        <option value="Draw">Draw</option>
                        <option value="Fade">Fade</option>
                        <option value="Push">Push</option>
                        <option value="Pull">Pull</option>
                        <option value="Push slice">Push slice</option>
                        <option value="Pull Hook">Pull Hook</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <button type="submit" className="btn btn-success btn-sm mt-2">
                      Guardar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </Form>
          <h5 className="my-4">Add Varilla</h5>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <table className="table var_form_table_class_informe">
              <thead>
                <tr>
                  <th scope="col">Cabeza de prueba</th>
                  <th scope="col">Fabrica de varilla</th>
                  <th scope="col">Modelo varilla</th>
                  <th scope="col">Flex</th>
                  <th scope="col">Material</th>
                  <th scope="col">Peso</th>
                  <th scope="col">Distancia (yds)</th>
                  <th scope="col">Ang. Salida (°)</th>
                  <th scope="col">Spin (rpm)</th>
                  <th scope="col">Fact Impacto (ind)</th>
                  <th scope="col">Vel. Bola (mph)</th>
                  <th scope="col">Vel Palo (mph)</th>
                  <th scope="col">Trayectoria</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input type="hidden" {...register('id_varilla_hierros')} />
                    <input type="hidden" value={id} {...register('id_informe_tecnico')} />
                    <Form.Group>
                      <Form.Select defaultValue="0" {...register('id_mod_cabeza')}>
                        <option value="#">Sel. mod. Cabeza</option>
                        <ListName url="modelo-cabeza-get" />
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Select defaultValue="0" {...register('id_marca')}>
                        <option value="#">Sel. fabrica</option>
                        <ListName url="fabricas-get" />
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Select defaultValue="0" {...register('id_modelo_cabeza')}>
                        <option value="#">Sel. mod. Varilla</option>
                        <ListName url="modelo-varilla-get" />
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Select defaultValue="0" {...register('id_flex')}>
                        <option value="#">Sel. flex</option>
                        <ListName url="flex-varilla-get" />
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Select defaultValue="0" {...register('id_material')}>
                        <option value="#">Sel. Material</option>
                        <ListName url="material-get" />
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Select
                        defaultValue="0"
                        {...register('peso')}
                      >
                        <option value="">Sel. peso</option>
                        <option value="40 gr">40 gr</option>
                        <option value="50 gr">50 gr</option>
                        <option value="60 gr">60 gr</option>
                        <option value="70 gr">70 gr</option>
                        <option value="80 gr">80 gr</option>
                        <option value="90 gr">90 gr</option>
                        <option value="100 gr">100 gr</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="distancia"
                        {...register('distancia')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="angSalida"
                        {...register('angSalida')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="spin"
                        {...register('spin')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="factImpacto"
                        {...register('factImpacto')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="velBola"
                        {...register('velBola')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group>
                      <Form.Control
                        required
                        type="text"
                        placeholder="velPalo"
                        {...register('velPalo')}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group id="gender">
                      <Form.Select
                        defaultValue="0"
                        {...register('trayectoria')}
                      >
                        <option value="">Sel. Trayectoría</option>
                        <option value="Straight">Straight</option>
                        <option value="Slice">Slice</option>
                        <option value="Hook">Hook</option>
                        <option value="Draw">Draw</option>
                        <option value="Fade">Fade</option>
                        <option value="Push">Push</option>
                        <option value="Pull">Pull</option>
                        <option value="Push slice">Push slice</option>
                        <option value="Pull Hook">Pull Hook</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td>
                    <button type="submit" className="btn btn-success btn-sm mt-2">
                      Guardar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </Form>
          {idVarillaHierros != null && (
            <>
              <h5 className="my-4">Varillas hierros</h5>
              <table className="table table_class_informe">
                <thead>
                  <tr>
                    <th scope="col" className='text-center'></th>
                    <th scope="col" className='text-center'>Modelo Cabeza</th>
                    <th scope="col" className='text-center'>Fabrica varillas</th>
                    <th scope="col" className='text-center'>Modelo Varilla</th>
                    <th scope="col" className='text-center'>Flex</th>
                    <th scope="col" className='text-center'>Material</th>
                    <th scope="col" className='text-center'>Peso</th>
                    <th scope="col" className='text-center'>Distancia (yds)</th>
                    <th scope="col" className='text-center'>Ang. Salida (°)</th>
                    <th scope="col" className='text-center'>Spin (rpm)</th>
                    <th scope="col" className='text-center'>Fact Impacto (ind)</th>
                    <th scope="col" className='text-center'>Vel. Bola (mph)</th>
                    <th scope="col" className='text-center'>Vel Palo (mph)</th>
                    <th scope="col" className='text-center'>Trayectoría</th>
                    <th scope="col" className='text-center'></th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(tablaVarillasHierros) && tablaVarillasHierros.map((val, key) => {
                    return <tr key={key}>
                      <td className="textCenter">
                        <input type="radio" value="" checked={val.ok} name="sel-var-hierros" id="banNo" onClick={() => { update(val.id, val.id_informe_tecnico) }} />
                      </td>
                      <td className='text-center'>{val.modCabeza}</td>
                      <td className='text-center'>{val.namMarca}</td>
                      <td className='text-center'>{val.nomModelo}</td>
                      <td className='text-center'>{val.namFlex}</td>
                      <td className='text-center'>{val.nomMaterial}</td>
                      <td className='text-center'>{val.peso}</td>
                      <td className='text-center'>{val.distancia}</td>
                      <td className='text-center'>{val.angSalida}</td>
                      <td className='text-center'>{val.spin}</td>
                      <td className='text-center'>{val.factImpacto}</td>
                      <td className='text-center'>{val.velBola}</td>
                      <td className='text-center'>{val.velPalo}</td>
                      <td className='text-center'>{val.trayectoria}</td>
                      <td>
                        <button type="button" className="btn btn-primary btn-sm"
                          onClick={() => { getVarillaHierrosId(val.id) }}>
                          Actualizar
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger btn-sm mx-2"
                          onClick={() => { deleteID(val.id) }}>
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  })
                  }
                </tbody>
              </table>
            </>
          )}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}
